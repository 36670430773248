// src/App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ChakraProvider, IconButton } from "@chakra-ui/react";
import { ChevronUpIcon } from "@chakra-ui/icons";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import Footer from "./components/Footer";
import FinancialAccounting from "./components/FinancialAccounting";
import OrderManagement from "./components/OrderManagement";
import Manufacturing from "./components/Manufacturing";
import CRM from "./components/CRM";
import Projects from "./components/Projects";
import Helpdesk from "./components/Helpdesk";
import AssetManagement from "./components/AssetManagement";
import ChartOfAccounts from "./components/ChartOfAccounts";
import JournalEntry from "./components/JouranalEntry";
import Bookkeeping from "./components/Bookkeeping";
import FurnitureManufacturers from "./components/FurnitureManufacturers";
import TextileProductManufacturers from "./components/TextileProductManufacturers";
import MetalFabricationManufacturers from "./components/MetalFabricationManufacturers";
import ElectronicsManufacturers from "./components/ElectronicsManufacturers";
import RubberProductsManufacturers from "./components/RubberProductsManufacturers";
import PlasticProductsManufacturers from "./components/PlasticProductsManufacturers";
import MedicalDeviceManufacturers from "./components/MedicalDeviceManufacturers";
import ChemicalManufacturers from "./components/ChemicalManufacturers";
import FoodManufacturers from "./components/FoodManufacturers";
import HRPayroll from "./components/HRPayroll";
import Pricing from "./components/Pricing";
import ScrollToTop from "./ScrollToTop";
import ComingSoon from "./components/ComingSoon";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Website from "./components/Website";

function App() {
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <ChakraProvider>
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<ComingSoon />} />
          <Route path="/erp/pricing" element={<Pricing />} />
          <Route path="/erp" element={<HeroSection />} />
          <Route
            path="/erp/financial-accounting"
            element={<FinancialAccounting />}
          />
          {/* <Route path="/charts-of-accounts" element={<ChartOfAccounts />} />
          <Route path="/journal-entry" element={<JournalEntry />} />
          <Route path="/bookkeeping" element={<Bookkeeping />} /> */}
          <Route path="/erp/order-management" element={<OrderManagement />} />
          <Route path="/erp/hr-and-payroll" element={<HRPayroll />} />
          <Route path="/erp/manufacturing" element={<Manufacturing />} />
          {/* <Route
            path="/furniture-manufacturers"
            element={<FurnitureManufacturers />}
          />
          <Route
            path="/textile-product-manufacturers"
            element={<TextileProductManufacturers />}
          />
          <Route
            path="/metal-fabrication-manufacturers"
            element={<MetalFabricationManufacturers />}
          />
          <Route
            path="/electronics-manufacturers"
            element={<ElectronicsManufacturers />}
          />
          <Route
            path="/rubber-products-manufacturers"
            element={<RubberProductsManufacturers />}
          />
          <Route
            path="/plastic-products-manufacturers"
            element={<PlasticProductsManufacturers />}
          />
          <Route
            path="/medical-device-manufacturers"
            element={<MedicalDeviceManufacturers />}
          />
          <Route
            path="/chemical-manufacturers"
            element={<ChemicalManufacturers />}
          /> */}
          {/* <Route
            path="/erp/food-manufacturers"
            element={<FoodManufacturers />}
          /> */}
          <Route path="/erp/crm" element={<CRM />} />
          <Route path="/erp/projects" element={<Projects />} />
          <Route path="/erp/helpdesk" element={<Helpdesk />} />
          <Route path="/erp/asset-management" element={<AssetManagement />} />
        </Routes>
        {/* <Website /> */}
        <Footer />

        {showScrollButton && (
          <IconButton
            icon={<ChevronUpIcon />}
            position="fixed"
            top="20px"
            right="20px"
            onClick={scrollToTop}
            bgGradient="linear(240deg, #FFDEE9 30%, #B5FFFC 100%)"
            color="black"
            aria-label="Scroll to top"
            _hover={{
              bgGradient: "linear(240deg, #B5FFFC 30%, #FFDEE9 100%)",
              color: "black",
            }}
            isRound
            size="lg"
          />
        )}
      </Router>
    </ChakraProvider>
  );
}

export default App;
