import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Heading,
  Text,
  Stack,
  Image,
  Flex,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"; // Import Framer Motion for animations
import dashboard from "../assets/accounts-dashboard.png";
import generalledger from "../assets/accounting-feature-general_ledger.jpg";
import bookkeeping from "../assets/bookkeeping-chart_of_accts.png";
import budget from "../assets/bookkeeping-budgets.png";
import autobookkeeping from "../assets/accounting-feature-subscription.jpg";
import dimensions from "../assets/bookkeeping-accounting_dims.png";
import reconcile from "../assets/accounting-feature-bank_reconcilation.jpg";
import multicurrency from "../assets/accounting-feature-sales_invoice.jpg";
import customizations from "../assets/customize-form.png";
import "./ModalAnimations.css";

const MotionBox = motion(Box);
const Bookkeeping = ({ isOpen, onClose, title }) => {
  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.classList.add("no-scroll");
  //   } else {
  //     document.body.classList.remove("no-scroll");
  //   }
  //   return () => {
  //     document.body.classList.remove("no-scroll");
  //   };
  // }, [isOpen]);
  const navigate = useNavigate();

  const handleLearnMore = (path) => {
    navigate(path);
  };
  return (
    <>
      {/* <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW="80vw" // Maximum width: 80% of the viewport width
          maxH="80vh" // Maximum height: 80% of the viewport height
          width="80vw" // Set width to 80% of the viewport width
          height="80vh" // Set height to 80% of the viewport height
          className={isOpen ? "modal-open" : "modal-close"}
        >
          <ModalCloseButton
            position="fixed" // Make the close button fixed
            top="10px" // Adjust the top position as needed
            right="10px" // Adjust the right position as needed
          />
          <ModalBody overflowY="auto"> */}
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          //   ref={journalRef}
        >
          <Heading fontSize="4xl" mb={4}>
            A 100% Open Source Bookkeeping Solution that's also an ERP
          </Heading>
          <Text fontSize="lg" mb={6}>
            ERPNext is a full-fledged, open-source bookkeeping software with the
            additional power of a full ERP. It includes all the tools you need
            to manage cash flow in one place, right from recording transactions
            to summarizing and analyzing financial reports.
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          ></Stack>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={dashboard}
            alt="Your Visual Bookkeeping Summary"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Your Visual Bookkeeping Summary
          </Heading>
          <Text fontSize="lg" mb={6}>
            ERPNext helps you understand your books visually with an accounts
            dashboard out of the box.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Make All Transactions Faster
          </Heading>
          <Text fontSize="lg" mb={6}>
            Create sales, purchase, and journal entries by either using the
            Quick Entry feature or by uploading in bulk.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={generalledger}
            alt="Make All Transactions Faster"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={bookkeeping}
            alt="Configure Your Chart of Accounts"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Configure Your Chart of Accounts
          </Heading>
          <Text fontSize="lg" mb={6}>
            Manage your bookkeeping the way you want. ERPNext gives you the
            flexibility to configure your own chart of accounts.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Control Your Expenses With Budgets
          </Heading>
          <Text fontSize="lg" mb={6}>
            Use our budgeting features to control your expenses and manage your
            liquidity better.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={budget}
            alt="Control Your Expenses With Budgets"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={autobookkeeping}
            alt="Auto-Invoicing = Auto-Bookkeeping"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Auto-Invoicing = Auto-Bookkeeping
          </Heading>
          <Text fontSize="lg" mb={6}>
            Have clients who you have to invoice regularly? Set the system to
            automatically deliver invoices and create accounting entries.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Add More Dimensions To Your Bookkeeping
          </Heading>
          <Text fontSize="lg" mb={6}>
            With accounting dimensions, you can add more ways of looking at your
            account books to make your transactions more insightful.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={dimensions}
            alt="Add More Dimensions To Your Bookkeeping"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={reconcile}
            alt="Reconcile Bank Accounts Quickly"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Reconcile Bank Accounts Quickly
          </Heading>
          <Text fontSize="lg" mb={6}>
            Use ERPNext's bank reconciliation to quickly do away with bank data
            entry discrepancies.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Multiple Currencies? No Problem.
          </Heading>
          <Text fontSize="lg" mb={6}>
            ERPnext's open source bookkeeping handles multiple currencies out of
            the box — no more ad-hoc conversions.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={multicurrency}
            alt="Multiple Currencies? No Problem."
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={customizations}
            alt="On-The-Fly Customizations"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            On-The-Fly Customizations
          </Heading>
          <Text fontSize="lg" mb={6}>
            Create your own bookkeeping software. Map the data you care about by
            adding custom fields in your forms. Then customize form behavior by
            auto-fetching values, hide fields based on user roles, and create
            custom print formats — all without a single line of code.
          </Text>
        </Box>
      </Flex>
      {/* </ModalBody>
        </ModalContent>
      </Modal> */}
    </>
  );
};

export default Bookkeeping;
