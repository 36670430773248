import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Heading,
  Text,
  Stack,
  Image,
  Flex,
  Switch,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import customizations from "../assets/customize-sales-invoice.png";
import customeraccess from "../assets/customer-access.png";
import email from "../assets/email.png";
import documentstorage from "../assets/documents-storage.png";
import allinone from "../assets/all-in-one-place.png";
import agile from "../assets/agile.png";
import todo from "../assets/todos-and-chat.png";
import budgetvariance from "../assets/budget-variance-report.png";
import projectbilling from "../assets/project-billing-summary.png";
import "./ModalAnimations.css";

// const Projects = ({ isOpen, onClose, title }) => {
const Projects = () => {
  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.classList.add("no-scroll");
  //   } else {
  //     document.body.classList.remove("no-scroll");
  //   }
  //   return () => {
  //     document.body.classList.remove("no-scroll");
  //   };
  // }, [isOpen]);

  const [showAdvancedText, setShowAdvancedText] = useState(true);
  const [showWorkspaceText, setShowWorkspaceText] = useState(true);
  const [showEmailText, setShowEmailText] = useState(true);
  const [showBudgetText, setShowBudgetText] = useState(true);

  const toggleText = () => setShowAdvancedText(!showAdvancedText);
  const toggleWorkspaceText = () => setShowWorkspaceText(!showWorkspaceText);
  const toggleEmailText = () => setShowEmailText(!showEmailText);
  const toggleBudgetText = () => setShowBudgetText(!showBudgetText);

  return (
    <>
      {/* <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW="80vw" // Maximum width: 80% of the viewport width
          maxH="80vh" // Maximum height: 80% of the viewport height
          width="80vw" // Set width to 80% of the viewport width
          height="80vh" // Set height to 80% of the viewport height
          className={isOpen ? "modal-open" : "modal-close"}
        >
          <ModalCloseButton
            position="fixed" // Make the close button fixed
            top="10px" // Adjust the top position as needed
            right="10px" // Adjust the right position as needed
            zIndex="1"
          />
          <ModalBody overflowY="auto"> */}
      <Flex
        justify="flex-start"
        align="center"
        py={0}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
        border={showAdvancedText ? "2px solid #0000FF" : "none"} // Electric green border when toggle is on
        borderRadius="md" // Optional, for rounded corners
        boxShadow={showAdvancedText ? "0 0 10px rgba(0, 0, 255, 0.7)" : "none"} // Electric glow effect
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          // ref={projectRef}
        >
          <Heading fontSize="4xl" mb={4}>
            Streamline your projects with the best open-source project
            management tool.
          </Heading>
          <Text fontSize="lg" mb={6}>
            {showAdvancedText
              ? "Let AI automatically create and assign tasks for you based on the project requirements"
              : "Plan, organize, and analyze projects with a simple, task-focused approach that boosts team collaboration and speeds up work."}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="view-toggle" mb="0">
                Supercharged with AI
              </FormLabel>
              <Switch
                id="view-toggle"
                isChecked={showAdvancedText}
                onChange={toggleText}
              />
            </FormControl>
          </Stack>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
        border={showWorkspaceText ? "2px solid #0000FF" : "none"} // Electric green border when toggle is on
        borderRadius="md" // Optional, for rounded corners
        boxShadow={showWorkspaceText ? "0 0 10px rgba(0, 0, 255, 0.7)" : "none"} // Electric glow effect
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={allinone}
            alt="All-In-One Workspace"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Manage all your projects in one place.
          </Heading>
          <Text fontSize="lg" mb={6}>
            {showWorkspaceText
              ? "Why keep logging into your work email when your AI can provide notifications/alerts on phone or mail"
              : "Stay on top of deadlines and progress, plan future projects with better accuracy, and forecast deadlines easily."}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="view-toggle" mb="0">
                Supercharged with AI
              </FormLabel>
              <Switch
                id="view-toggle"
                isChecked={showWorkspaceText}
                onChange={toggleWorkspaceText}
              />
            </FormControl>
          </Stack>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Manage projects with flexibility and transparency.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Simplify project management by breaking tasks into smaller pieces,
            assigning them clearly, and collaborating effortlessly with the
            team.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={agile}
            alt="Agile"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={todo}
            alt="To-Do List & Real-Time Chat"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Stay on track with tasks and real-time communication.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Assign tasks and manage to-do lists automatically. Use built-in chat
            for instant collaboration and avoid switching between apps.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Organize and share project documents easily.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Upload and share documents effortlessly, making sure all project
            files are accessible to your team.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={documentstorage}
            alt="Document Storage"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
        border={showEmailText ? "2px solid #0000FF" : "none"} // Electric green border when toggle is on
        borderRadius="md" // Optional, for rounded corners
        boxShadow={showEmailText ? "0 0 10px rgba(0, 0, 255, 0.7)" : "none"} // Electric glow effect
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={email}
            alt="Emails"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Stay connected with smooth email communication.
          </Heading>
          <Text fontSize="lg" mb={6}>
            {showEmailText
              ? "Just tell our AI what you need to convey and it will create professional communication mails for you"
              : "Sync emails in uniQ ERP Tool for smooth communication with clients and team members—never miss important messages."}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="view-toggle" mb="0">
                Supercharged with AI
              </FormLabel>
              <Switch
                id="view-toggle"
                isChecked={showEmailText}
                onChange={toggleEmailText}
              />
            </FormControl>
          </Stack>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
        border={showBudgetText ? "2px solid #0000FF" : "none"} // Electric green border when toggle is on
        borderRadius="md" // Optional, for rounded corners
        boxShadow={showBudgetText ? "0 0 10px rgba(0, 0, 255, 0.7)" : "none"} // Electric glow effect
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Track project budgets and expenses in real time.
          </Heading>
          <Text fontSize="lg" mb={6}>
            {showBudgetText
              ? "Let AI keep track of project budget / expenses and keep you updated through alerts over texts / mails"
              : "Keep track of budgets, plan costs, and see project expenses in real time to stay within budget."}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="view-toggle" mb="0">
                Supercharged with AI
              </FormLabel>
              <Switch
                id="view-toggle"
                isChecked={showBudgetText}
                onChange={toggleBudgetText}
              />
            </FormControl>
          </Stack>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={budgetvariance}
            alt="Profitability Analysis"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={projectbilling}
            alt="Time-Tracking & Billing"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Simplify time tracking and billing for your projects.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Track project hours and expenses, link them to payroll, and get
            instant insights into profitability.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Provide clients with transparent access to project details.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Allow clients to view project progress, raise issues, and access
            invoices all in one place for a seamless experience.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={customeraccess}
            alt="Customer Access"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={customizations}
            alt="On-The-Fly Customizations"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            On-The-Fly Customizations
          </Heading>
          <Text fontSize="lg" mb={6}>
            Customize your accounting software to fit your needs—no coding
            required!
          </Text>
        </Box>
      </Flex>
      {/* </ModalBody>
        </ModalContent>
      </Modal> */}
    </>
  );
};

export default Projects;
