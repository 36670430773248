import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Heading,
  Text,
  Stack,
  Image,
  Flex,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"; // Import Framer Motion for animations
import accountants from "../assets/accountants.png";
import journalentry from "../assets/journal-entry.png";
import journaltracking from "../assets/journal-entry-tracking.png";
import journalreverse from "../assets/journal-entry-reverse.png";
import journaldifference from "../assets/journal-entry-difference.png";
import journalmulticurrency from "../assets/journal-entry-multicurrency.png";
import journalcustomize from "../assets/customize-form.png";
import "./ModalAnimations.css";

const MotionBox = motion(Box);
// const JournalEntry = ({ isOpen, onClose, title }) => {
const JournalEntry = () => {
  const navigate = useNavigate();

  const handleLearnMore = (path) => {
    navigate(path);
  };
  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.classList.add("no-scroll");
  //   } else {
  //     document.body.classList.remove("no-scroll");
  //   }
  //   return () => {
  //     document.body.classList.remove("no-scroll");
  //   };
  // }, [isOpen]);
  return (
    <>
      {/* <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW="80vw" // Maximum width: 80% of the viewport width
          maxH="80vh" // Maximum height: 80% of the viewport height
          width="80vw" // Set width to 80% of the viewport width
          height="80vh" // Set height to 80% of the viewport height
          className={isOpen ? "modal-open" : "modal-close"}
        >
          <ModalCloseButton
            position="fixed" // Make the close button fixed
            top="10px" // Adjust the top position as needed
            right="10px" // Adjust the right position as needed
          />
          <ModalBody overflowY="auto"> */}
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          //   ref={journalRef}
        >
          <Heading fontSize="4xl" mb={4}>
            The complete accounting solution with all types of journal entries
          </Heading>
          <Text fontSize="lg" mb={6}>
            Leave no transaction unrecorded. Make quick journal entries,
            difference entries, reverse entries, contra entries, and more — all
            in a few simple clicks with ERPNext, the world's best open source
            accounting ERP.
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          ></Stack>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={accountants}
            alt="Save Accountants Time"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Save Accountants Time
          </Heading>
          <Text fontSize="lg" mb={6}>
            Make quick journal entries — it takes just 4 fields. You can also
            bulk upload journal entries using the Excel upload feature.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Use Journal Entries To Record More Transactions
          </Heading>
          <Text fontSize="lg" mb={6}>
            Create contra entries, debit notes, credit notes, bank entries and
            intra-company transactions, or even write-off bad debts… ERPNext's
            journal entries are a powerful doctype.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={journalentry}
            alt="Use Journal Entries To Record More Transactions"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={journaltracking}
            alt="Track Everything Better"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Track Everything Better
          </Heading>
          <Text fontSize="lg" mb={6}>
            Add new cost centers or projects, or give reference names to
            sales/purchase orders, to track journal entries separately.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Reverse Journal Entry In One Click
          </Heading>
          <Text fontSize="lg" mb={6}>
            No need to worry — ERPNext has a dedicated button to reverse any
            journal entry in just a moment.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={journalreverse}
            alt="Reverse Journal Entry In One Click"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={journaldifference}
            alt="Create Difference Entries"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Create Difference Entries
          </Heading>
          <Text fontSize="lg" mb={6}>
            Keep your accounting spotless by creating difference entries for any
            amounts that are not accounted for by existing accounts.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Multi-Currency Journal Entries
          </Heading>
          <Text fontSize="lg" mb={6}>
            Dealing with international customers? Don't worry, ERPNext allows
            for multi-currency journal entries. We'll even convert everything to
            your base currency if you want.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={journalmulticurrency}
            alt="Multi-Currency Journal Entries"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={journalcustomize}
            alt="Customize Your Journal Entry"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Customize Your Journal Entry
          </Heading>
          <Text fontSize="lg" mb={6}>
            Want to capture more information? No worries, you can customize your
            forms without any experts or consultants. Add custom fields in your
            forms, customize form behavior, auto-fetch form values, hide fields
            for certain users, and create custom print formats — all without a
            single line of code.
          </Text>
        </Box>
      </Flex>
      {/* </ModalBody>
        </ModalContent>
      </Modal> */}
    </>
  );
};

export default JournalEntry;
