import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Heading,
  Text,
  Stack,
  Image,
  Flex,
} from "@chakra-ui/react";
import { motion } from "framer-motion"; // Import Framer Motion for animations
import empowerment from "../assets/manufacturing-documentation.png";
import customizations from "../assets/customize-production-plan.png";
import stock from "../assets/stock-replenishment-furniture.png";
import supplier from "../assets/supplier-portal.png";
import muom from "../assets/multiple-unit-of-measures.png";
import inventory from "../assets/furniture-inventory.png";
import capacity from "../assets/capacity-planning.png";
import subcontracting from "../assets/subcontracting.png";
import mrp from "../assets/mrp-furniture.png";
import shopfloor from "../assets/shopfloor-management.png";
import browser from "../assets/bom-browser.png";
import bom from "../assets/bom.png";
import variant from "../assets/furniture-variants.png";
import production from "../assets/production-analytics.png";
import furniture from "../assets/furniture-manufacturing-industry.jpg";
import "./ModalAnimations.css";

const MotionBox = motion(Box);
// const MedicalDeviceManufacturers = ({ isOpen, onClose, title }) => {
const MedicalDeviceManufacturers = () => {
  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.classList.add("no-scroll");
  //   } else {
  //     document.body.classList.remove("no-scroll");
  //   }
  //   return () => {
  //     document.body.classList.remove("no-scroll");
  //   };
  // }, [isOpen]);
  return (
    <>
      {/* <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent
            maxW="80vw" // Maximum width: 80% of the viewport width
            maxH="80vh" // Maximum height: 80% of the viewport height
            width="80vw" // Set width to 80% of the viewport width
            height="80vh" // Set height to 80% of the viewport height
            className={isOpen ? "modal-open" : "modal-close"}
          >
            <ModalCloseButton
              position="fixed" // Make the close button fixed
              top="10px" // Adjust the top position as needed
              right="10px" // Adjust the right position as needed
            />
            <ModalBody overflowY="auto"> */}
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          // ref={websiteRef}
        >
          <Heading fontSize="4xl" mb={4}>
            Open Source, Modern, DIY ERP for Furniture Manufacturers
          </Heading>
          <Text fontSize="lg" mb={6}>
            Say goodbye to your legacy tools with a contemporary manufacturing
            ERP software that simplifies your furniture business and improves
            performance.
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          ></Stack>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={furniture}
            alt="Best-In-Class Furniture Software"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Best-In-Class Furniture Software
          </Heading>
          <Text fontSize="lg" mb={6}>
            Turning creative designs into beautiful furniture might be your
            daily gig, but you need more than great craftsmanship to run a
            successful manufacturing business. Increase productivity, manage
            your supply chain smoothly and get your furniture to distributors
            and consumers faster with ERPNext. It can handle even the greatest
            volumes, so keep growing. Take advantage of its integrated inventory
            to process orders faster, manage suppliers, store documents,
            customize everything on the fly, and much more.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Total Control Over Your Production Cycle
          </Heading>
          <Text fontSize="lg" mb={6}>
            Keep an eye on your furniture manufacturing business and ensure
            orders are fulfilled with our Production Analytics Report — an
            aggregated view of production status. It includes several reports to
            help you analyze manufacturing load and make insightful decisions.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={production}
            alt="Total Control Over Your Production Cycle"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={variant}
            alt="Item Variants"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Item Variants
          </Heading>
          <Text fontSize="lg" mb={6}>
            Managing thousands of product permutations — such as finishes,
            fabrics, shapes, and sizes — is enormously complex. In ERPNext, the
            primary item is stored as an item template, and each of the
            variations is stored as an item variant.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Bill Of Material (BOM)
          </Heading>
          <Text fontSize="lg" mb={6}>
            A Bill of Material is at the heart of any manufacturing ERP
            software, and a well-defined BOM ensures robustness and accuracy.
            ERPNext's BOM is hierarchical, with the finished product at the top.
            It also includes item codes, raw materials, quantities, costs and
            additional specifications.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={bom}
            alt="Bill Of Material (BOM)"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={browser}
            alt="BOM Browser"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            BOM Browser
          </Heading>
          <Text fontSize="lg" mb={6}>
            Navigate through the multiple levels of your BOMs and understand the
            foundation of your furniture products. With the BOM Browser, you can
            not only view the BOMs of sub-assemblies, but also update them in
            real time to maintain accurate costs based on stock value and
            ongoing procurements.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Shop Floor Management
          </Heading>
          <Text fontSize="lg" mb={6}>
            With the Job Cards, Operations, and Workstations features, you can
            automatically capture mundane activities on your shop floor. A
            single screen shows the real-time status of job work, the location
            of each workstation, employee assignments, and the status and next
            steps of each work order.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={shopfloor}
            alt="Shop Floor Management"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={mrp}
            alt="Material Resource Planning"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Material Resource Planning
          </Heading>
          <Text fontSize="lg" mb={6}>
            Material procurement and production planning for furniture
            manufacturing can become a nightmare. Be it a work order against
            sales or material requests, ERPNext will show every aspect of your
            MRP. Track material consumption of batched inventory to curb
            expenses and achieve zero wasted resources.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Subcontracting
          </Heading>
          <Text fontSize="lg" mb={6}>
            A furniture business is incomplete without outsourcing. Be it
            polishing or varnishing, some jobs have to be outsourced. Use the
            Subcontracting feature to supply raw materials to a third-party
            distributor or supplier and easily track all activities.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={subcontracting}
            alt="Subcontracting"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={capacity}
            alt="Capacity Planning"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Capacity Planning
          </Heading>
          <Text fontSize="lg" mb={6}>
            Optimize your workstations based on the resources available for open
            production orders. Identify and eliminate bottlenecks at the
            shop-floor level by creating a capacity plan. You can also maintain
            and manage planning schedules for a predefined period of time (30
            days, 45 days, etc).
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Batched Inventory
          </Heading>
          <Text fontSize="lg" mb={6}>
            If you produce your furniture in batches, the Batch master will come
            in handy to maintain manufacturing dates, expiration dates, and
            other details. Scan item barcodes with your device camera to easily
            search through your stock and make data-driven decisions.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={inventory}
            alt="Batched Inventory"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={muom}
            alt="Multiple Units Of Measurement"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Multiple Units Of Measurement
          </Heading>
          <Text fontSize="lg" mb={6}>
            Don't let systems stop you from measuring materials exactly the way
            you want! Keep track of different units of measurement for your
            inventory. Set up conversion factors in the Item master itself and
            facilitate smooth sales, purchase, and stock transactions. All
            conversions will be handled by the system.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Supplier Portal
          </Heading>
          <Text fontSize="lg" mb={6}>
            Use the supplier portal to help suppliers manage themselves. The
            suppliers can submit requests for quotations and automatic supplier
            quotations, or access ongoing purchase orders and receipts.
            Suppliers can also raise purchase invoices for fulfilled orders. You
            can then verify these invoices for final approval.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={supplier}
            alt="Supplier Portal"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={stock}
            alt="Stock Replenishment"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Stock Replenishment
          </Heading>
          <Text fontSize="lg" mb={6}>
            To prevent stockouts, track an item's reorder level. When the stock
            level goes below the reorder level, ERPNext will automatically
            create a material request. This helps you replenish inventory at
            exactly the right time and reduce chances of stock exhaustion.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            On-The-Fly Customizations
          </Heading>
          <Text fontSize="lg" mb={6}>
            Create your own custom furniture manufacturing ERP software. Map
            your most important data by adding custom fields in your forms.
            Customize form behavior by auto-fetching values, hide fields based
            on user roles, and create custom print formats — all without a
            single line of code.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={customizations}
            alt="On-The-Fly Customizations"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={empowerment}
            alt="Self-Onboarding & Empowerment"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Self-Onboarding & Empowerment
          </Heading>
          <Text fontSize="lg" mb={6}>
            All the tools you need to achieve manufacturing excellence are part
            of the world's first smart, DIY ERP. Transform your organization
            with excellent documentation and video tutorials that facilitate a
            zero-touch, super fast ERP implementation.
          </Text>
        </Box>
      </Flex>
      {/* </ModalBody>
          </ModalContent>
        </Modal> */}
    </>
  );
};

export default MedicalDeviceManufacturers;
