import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Heading,
  Text,
  Stack,
  Image,
  Flex,
  Switch,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import customizations from "../assets/customize-sales-invoice.png";
import omnichannel from "../assets/omnichannel-interactions.png";
import realtime from "../assets/help-desk-software-dashboard.png";
import automateticket from "../assets/automating-ticket.png";
import teamexcellence from "../assets/support-issue.png";
import servicelevel from "../assets/service-level.png";
import customerportal from "../assets/helpdesk-customer-issue-portal.png";
import article from "../assets/help-article.png";
import maintenance from "../assets/maintenance-schedule.png";
import "./ModalAnimations.css";

// const Helpdesk = ({ isOpen, onClose, title }) => {
const Helpdesk = () => {
  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.classList.add("no-scroll");
  //   } else {
  //     document.body.classList.remove("no-scroll");
  //   }
  //   return () => {
  //     document.body.classList.remove("no-scroll");
  //   };
  // }, [isOpen]);
  const [showAdvancedText, setShowAdvancedText] = useState(true);
  const toggleText = () => setShowAdvancedText(!showAdvancedText);

  return (
    <>
      {/* <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent
            maxW="80vw" // Maximum width: 80% of the viewport width
            maxH="80vh" // Maximum height: 80% of the viewport height
            width="80vw" // Set width to 80% of the viewport width
            height="80vh" // Set height to 80% of the viewport height
            className={isOpen ? "modal-open" : "modal-close"}
          >
            <ModalCloseButton
              position="fixed" // Make the close button fixed
              top="10px" // Adjust the top position as needed
              right="10px" // Adjust the right position as needed
              zIndex="1"
            />
            <ModalBody overflowY="auto"> */}
      <Flex
        justify="flex-start"
        align="center"
        py={0}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          // ref={helpdeskRef}
        >
          <Heading fontSize="4xl" mb={4}>
            Enhance your customer service with an open-source help desk.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Take control of your support with uniQ ERP Tool—manage tickets,
            automate tasks, track SLAs, and more.
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          ></Stack>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={realtime}
            alt="Level Up With Real-Time Insights"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Gain real-time insights into your support operations.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Use real-time dashboards to quickly spot bottlenecks and improve
            your support processes.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Keep track of all customer interactions in one place.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Convert calls, emails, and chats into tickets effortlessly and
            receive real-time notifications.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={omnichannel}
            alt="Omnichannel Interactions"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={automateticket}
            alt="Automate Ticket Assignments"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Automate ticket assignment and boost team efficiency.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Automatically assign tickets to your team with rules like
            round-robin or load balancing to ensure quick responses.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Boost your support team’s performance.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Monitor your team's performance and collaborate easily with built-in
            reports on ticket traffic.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={teamexcellence}
            alt="Team Excellence"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={servicelevel}
            alt="Service Level Agreement"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Manage SLAs and exceed customer expectations.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Set and track SLAs to improve response and resolution times based on
            customer expectations.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Give customers real-time access to their support status.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Empower your customers with transparent access to their support
            tickets, orders, and shipments through the customer portal.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={customerportal}
            alt="Customer Portal"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
        border={showAdvancedText ? "2px solid #0000FF" : "none"} // Electric green border when toggle is on
        borderRadius="md" // Optional, for rounded corners
        boxShadow={showAdvancedText ? "0 0 10px rgba(0, 0, 255, 0.7)" : "none"} // Electric glow effect
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={article}
            alt="Knowledge Base"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Reduce ticket volume with helpful FAQs and articles.
          </Heading>
          <Text fontSize="lg" mb={6}>
            {showAdvancedText
              ? "Allow AI to create tickets effortlessly and offer useful on-the-spot suggestions to your customers"
              : "Cut down on ticket volume by offering helpful FAQs and knowledge articles that allow customers to find solutions quickly."}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="view-toggle" mb="0">
                Supercharged with AI
              </FormLabel>
              <Switch
                id="view-toggle"
                isChecked={showAdvancedText}
                onChange={toggleText}
              />
            </FormControl>
          </Stack>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Never miss a maintenance visit again.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Schedule and track maintenance visits with ease—log work and gather
            feedback in one click.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={maintenance}
            alt="Scheduling Maintenance Visits"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={customizations}
            alt="On-The-Fly Customizations"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            On-The-Fly Customizations
          </Heading>
          <Text fontSize="lg" mb={6}>
            Customize your accounting software to fit your needs—no coding
            required!
          </Text>
        </Box>
      </Flex>
      {/* </ModalBody>
          </ModalContent>
        </Modal> */}
    </>
  );
};

export default Helpdesk;
