import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Heading,
  Text,
  Button,
  Stack,
  Image,
  Flex,
  SimpleGrid,
  Switch,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"; // Import Framer Motion for animations
import { useInView } from "react-intersection-observer"; // For triggering animations when in view
import items from "../assets/item-variants.png";
import customizations from "../assets/customize-sales-invoice.png";
import totalcontrolproduction from "../assets/production-analytics.png";
import bill from "../assets/bill-of-materials.png";
import shopfloor from "../assets/shopfloor-management.png";
import subcontracting from "../assets/subcontracting-stock-entry.png";
import batchedinventory from "../assets/batched-inventory.png";
import serialisedinventory from "../assets/serialised-inventory.png";
import resourceplanning from "../assets/production-plan.png";
import capacityplanning from "../assets/capacity-planning.png";
import uoms from "../assets/multiple-uoms.png";
import stockbalance from "../assets/quick-stock-balance-report.png";
import stockreplenishment from "../assets/automate-stock-replenishment6915b5.png";
import "./ModalAnimations.css";
// import FurnitureManufacturers from "./FurnitureManufacturers";
// import TextileProductManufacturers from "./TextileProductManufacturers";
// import MetalFabricationsManufacturers from "./MetalFabricationManufacturers";
// import ElectronicsManufacturers from "./ElectronicsManufacturers";
// import RubberProductsManufacturers from "./RubberProductsManufacturers";
// import PlasticProductsManufacturers from "./PlasticProductsManufacturers";
// import MedicalDeviceManufacturers from "./MedicalDeviceManufacturers";
// import ChemicalManufacturers from "./ChemicalManufacturers";
// import FoodManufacturers from "./FoodManufacturers";

const MotionBox = motion(Box);
// const Manufacturing = ({ isOpen, onClose, title }) => {
const Manufacturing = () => {
  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.classList.add("no-scroll");
  //   } else {
  //     document.body.classList.remove("no-scroll");
  //   }
  //   return () => {
  //     document.body.classList.remove("no-scroll");
  //   };
  // }, [isOpen]);

  // const [openModal, setOpenModal] = useState(""); // Track which modal is open

  // const handleModalOpen = (modalName) => {
  //   setOpenModal(modalName);
  // };

  // const handleModalClose = () => {
  //   setOpenModal("");
  // };
  const navigate = useNavigate();

  const handleLearnMore = (path) => {
    navigate(path);
  };

  const [showAdvancedText, setShowAdvancedText] = useState(true);
  const [showResourceText, setShowResourceText] = useState(true);
  const [showCapacityText, setShowCapacityText] = useState(true);

  const toggleText = () => setShowAdvancedText(!showAdvancedText);
  const toggleCapacityText = () => setShowCapacityText(!showCapacityText);
  const toggleResourceText = () => setShowResourceText(!showResourceText);

  return (
    <>
      {/* <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW="80vw" // Maximum width: 80% of the viewport width
          maxH="80vh" // Maximum height: 80% of the viewport height
          width="80vw" // Set width to 80% of the viewport width
          height="80vh" // Set height to 80% of the viewport height
          className={isOpen ? "modal-open" : "modal-close"}
        >
          <ModalCloseButton
            position="fixed" // Make the close button fixed
            top="10px" // Adjust the top position as needed
            right="10px" // Adjust the right position as needed
            zIndex="1"
          />
          <ModalBody overflowY="auto"> */}
      <Flex
        justify="flex-start"
        align="center"
        py={0}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          // ref={manufacturingRef}
        >
          <Heading fontSize="4xl" mb={4}>
            Upgrade to a modern cloud-based ERP for manufacturing.
          </Heading>
          <Text fontSize="lg" mb={6}>
            A smart, open-source ERP that streamlines production, tracks
            materials, plans capacity, and manages subcontracting.
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          ></Stack>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={totalcontrolproduction}
            alt="Total control over production"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Get full control over your production process.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Easily integrate production planning with shop floor operations and
            coordinate sales, inventory, purchasing, and finance in real time.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
        border={showAdvancedText ? "2px solid #0000FF" : "none"} // Electric green border when toggle is on
        borderRadius="md" // Optional, for rounded corners
        boxShadow={showAdvancedText ? "0 0 10px rgba(0, 0, 255, 0.7)" : "none"} // Electric glow effect
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Easily manage your bill of materials (BOM).
          </Heading>
          <Text fontSize="lg" mb={6}>
            {showAdvancedText
              ? "Allow AI to create a bills of material for you, based on your dimensions / drawings."
              : "uniQ ERP Tool's BOM is simple, structured, and includes all key details like product codes, part descriptions, and costs."}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="view-toggle" mb="0">
                Supercharged with AI
              </FormLabel>
              <Switch
                id="view-toggle"
                isChecked={showAdvancedText}
                onChange={toggleText}
              />
            </FormControl>
          </Stack>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={bill}
            alt="Bill of material"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={shopfloor}
            alt="Shop floor management"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Manage your shop floor with real-time updates.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Monitor job progress, employee tasks, and workstation statuses in
            real time on one screen.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Easily manage outsourcing with uniQ ERP tool.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Track outsourced work, manage raw material supply, and calculate
            costs of subcontracted services with ease.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={subcontracting}
            alt="Subcontracting"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={items}
            alt="Item variants"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Manage product variations in a snap.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Store products and all their variations (color, size, shape) easily
            in your uniQ ERP tool.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Simplify batch inventory management.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Manage batches, track manufacturing and expiration dates, and scan
            barcodes for fast inventory decisions.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={batchedinventory}
            alt="Batched inventory"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={serialisedinventory}
            alt="Serialized inventory"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Track products with serial numbers for better control.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Assign serial numbers to items and scan barcodes for fast, easy
            tracking of your stock.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
        border={showResourceText ? "2px solid #0000FF" : "none"} // Electric green border when toggle is on
        borderRadius="md" // Optional, for rounded corners
        boxShadow={showResourceText ? "0 0 10px rgba(0, 0, 255, 0.7)" : "none"} // Electric glow effect
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Plan materials efficiently to avoid waste.
          </Heading>
          <Text fontSize="lg" mb={6}>
            {showResourceText
              ? "Our AI can automatically allocate resources to maximize efficiency"
              : "Track material consumption and manage resources effectively to minimize waste and cut costs."}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="view-toggle" mb="0">
                Supercharged with AI
              </FormLabel>
              <Switch
                id="view-toggle"
                isChecked={showResourceText}
                onChange={toggleResourceText}
              />
            </FormControl>
          </Stack>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={resourceplanning}
            alt="Material resource planning"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
        border={showCapacityText ? "2px solid #0000FF" : "none"} // Electric green border when toggle is on
        borderRadius="md" // Optional, for rounded corners
        boxShadow={showCapacityText ? "0 0 10px rgba(0, 0, 255, 0.7)" : "none"} // Electric glow effect
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={capacityplanning}
            alt="Capacity planning"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Optimize production resources and eliminate bottlenecks.
          </Heading>
          <Text fontSize="lg" mb={6}>
            {showCapacityText
              ? "Our AI will analyze your entire supply chain to optimize operations and suggest actionable next-steps for improvement"
              : "Plan production capacity, identify bottlenecks, and optimize resources across workstations."}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="view-toggle" mb="0">
                Supercharged with AI
              </FormLabel>
              <Switch
                id="view-toggle"
                isChecked={showCapacityText}
                onChange={toggleCapacityText}
              />
            </FormControl>
          </Stack>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Measure materials in multiple units without any hassle.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Track materials in any unit of measure and set automatic conversions
            for smooth transactions.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={uoms}
            alt="Multiple units of measure"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={stockbalance}
            alt="Quick stock balance"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Quickly verify stock levels with ease.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Verify stock quickly using barcode scanning—no special equipment
            needed!
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Automate stock replenishment to avoid running out of inventory.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Automatically reorder stock when levels drop below the
            threshold—never run out of inventory again.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={stockreplenishment}
            alt="Stock replenishment"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={customizations}
            alt="On-the-fly customizations"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            On-the-fly customizations
          </Heading>
          <Text fontSize="lg" mb={6}>
            Customize your accounting software to fit your needs—no coding
            required!
          </Text>
        </Box>
      </Flex>
      {/* <Box py={20} px={8} bg="gray.100">
        <Heading textAlign="center" fontSize="3xl" mb={12}>
          Almost everything you need
        </Heading>
        <Text textAlign="center" mb={12}>
          ERPNext comes with 1000+ objects to help you run your business
        </Text>
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          spacing={10}
          px={{ base: 4, md: 20 }}
        >
          <FeatureBox
            title="Furniture Manufacturers"
            description="Turning creative designs into beautiful furniture might be your daily gig, but you need more than great craftsmanship to run a successful manufacturing business. "
            onLearnMore={() => handleLearnMore("/furniture-manufacturers")}
          />
          <FeatureBox
            title="Textile Product Manufacturers"
            description="Needless to say, stitching together quality clothing is not easy. ERPNext is woven together to help maintain your textile manufacturing line with ease. "
            onLearnMore={() =>
              handleLearnMore("/textile-product-manufacturers")
            }
          />
          <FeatureBox
            title="Metal Fabrication Manufacturers"
            description="Set aside your spreadsheets and focus on cutting metal. ERPNext governs your manufacturing so you can create precision-cut machinery that will stand the test of time."
            onLearnMore={() =>
              handleLearnMore("/metal-fabrication-manufacturers")
            }
          />
          <FeatureBox
            title="Electronics Manufacturers"
            description="From serialization of electronic accessories to managing material movements, tackle complexities of the electronics business intelligently."
            onLearnMore={() => handleLearnMore("/electronics-manufacturers")}
          />
          <FeatureBox
            title="Rubber Products Manufacturers"
            description="Manufacturing rubber products can get tiresome. Transform your rubber manufacturing with our ERP that lets you bounce back to work, day in and day out."
            onLearnMore={() =>
              handleLearnMore("/rubber-products-manufacturers")
            }
          />
          <FeatureBox
            title="Plastic Products Manufacturers"
            description="Be it using new materials or processing a big contract, control your plastic manufacturing with material resource planning, work orders, and more."
            onLearnMore={() =>
              handleLearnMore("/plastic-products-manufacturers")
            }
          />
          <FeatureBox
            title="Medical Device Manufacturers"
            description="The last thing you need in medical device manufacturing is more paperwork or complex software. Focus on manufacturing quality devices while ERPNext records all your details on the cloud."
            onLearnMore={() => handleLearnMore("/medical-device-manufacturers")}
          />
          <FeatureBox
            title="Chemical Manufacturers"
            description="From industrial to daily use, chemicals are needed in every industry. Manage your chemical manufacturing business with a tool that can be formulated to suit all your needs."
            onLearnMore={() => handleLearnMore("/chemical-manufacturers")}
          />
          <FeatureBox
            title="Food Manufacturers"
            description="Food manufacturing is complex, be it quality assurance checks, constantly changing food prices, or eco-friendly packaging. ERPNext will help you beat the competition."
            onLearnMore={() => handleLearnMore("/food-manufacturers")}
          />
        </SimpleGrid>
      </Box> */}
      {/* <FurnitureManufacturers
        isOpen={openModal === "FurnitureManufacturers"}
        onClose={handleModalClose}
        title="FurnitureManufacturers"
      />
      <TextileProductManufacturers
        isOpen={openModal === "TextileProductManufacturers"}
        onClose={handleModalClose}
        title="TextileProductManufacturers"
      />
      <MetalFabricationsManufacturers
        isOpen={openModal === "MetalFabricationsManufacturers"}
        onClose={handleModalClose}
        title="MetalFabricationsManufacturers"
      />
      <ElectronicsManufacturers
        isOpen={openModal === "ElectronicsManufacturers"}
        onClose={handleModalClose}
        title="ElectronicsManufacturers"
      />
      <RubberProductsManufacturers
        isOpen={openModal === "RubberProductsManufacturers"}
        onClose={handleModalClose}
        title="RubberProductsManufacturers"
      />
      <PlasticProductsManufacturers
        isOpen={openModal === "PlasticProductsManufacturers"}
        onClose={handleModalClose}
        title="PlasticProductsManufacturers"
      />
      <MedicalDeviceManufacturers
        isOpen={openModal === "MedicalDeviceManufacturers"}
        onClose={handleModalClose}
        title="MedicalDeviceManufacturers"
      />
      <ChemicalManufacturers
        isOpen={openModal === "ChemicalManufacturers"}
        onClose={handleModalClose}
        title="ChemicalManufacturers"
      />
      <FoodManufacturers
        isOpen={openModal === "FoodManufacturers"}
        onClose={handleModalClose}
        title="FoodManufacturers"
      /> */}
      {/* </ModalBody>
        </ModalContent>
      </Modal> */}
    </>
  );
};

// const FeatureBox = ({ title, description, onLearnMore }) => {
//   // Intersection Observer for triggering animations
//   const { ref, inView } = useInView({
//     triggerOnce: false, // Only animate once
//     threshold: 0.1, // Trigger when 10% of the component is in view
//   });

//   return (
//     <MotionBox
//       ref={ref}
//       textAlign="center"
//       p={6}
//       bg="white"
//       borderRadius="lg"
//       boxShadow="lg"
//       initial={{ opacity: 0, y: 50 }}
//       animate={inView ? { opacity: 1, y: 0 } : {}} // Animate to visible state when in view
//       transition={{ duration: 0.5 }}
//       whileHover={{
//         scale: 1.05,
//         backgroundColor: "#f0f4ff",
//         boxShadow: "xl",
//       }}
//     >
//       <Heading fontSize="2xl" mb={2} fontWeight="bold" color="gray.800">
//         {title}
//       </Heading>
//       <Text mb={4} fontSize="lg" color="gray.600">
//         {description}
//       </Text>
//       <Button
//         variant="solid"
//         colorScheme="teal"
//         size="lg"
//         onClick={onLearnMore}
//       >
//         Learn more →
//       </Button>
//     </MotionBox>
//   );
// };

export default Manufacturing;
