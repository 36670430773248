import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Stack,
  Image,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Switch,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import multicurrency from "../assets/multi-currency-accounting.png";
import autoinvoice from "../assets/subscription-plan.png";
import customizations from "../assets/customize-sales-invoice.png";
import salesdashboard from "../assets/sales-dashboard.png";
import items from "../assets/item-variants.png";
import trackserialnumbers from "../assets/track-serial-numbers.png";
import managelots from "../assets/manage-lots.png";
import multichannel from "../assets/multi-channels.png";
import printformats from "../assets/print-formats.png";
import generalledgersales from "../assets/general-ledger-sales.png";
import marketingtools from "../assets/marketing-tools.png";
import accountreceivable from "../assets/accounts-receivable.png";
import customeraccess from "../assets/customer-access.png";
import email from "../assets/email.png";
import documentstorage from "../assets/documents-storage.png";
import stock from "../assets/automate-stock-replenishment.png";
import promotional from "../assets/promotional-schemes.png";
import vendors from "../assets/vendors.png";
import supplierportal from "../assets/computer-supplier-portal.png";
import accountspayable from "../assets/accounts-payable.png";
import diy from "../assets/diy.png";
import "./ModalAnimations.css";

// const OrderManagement = ({ isOpen, onClose, title }) => {
const OrderManagement = () => {
  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.classList.add("no-scroll");
  //   } else {
  //     document.body.classList.remove("no-scroll");
  //   }
  //   return () => {
  //     document.body.classList.remove("no-scroll");
  //   };
  // }, [isOpen]);
  const [showAdvancedText, setShowAdvancedText] = useState(true);
  const [showCRMText, setShowCRMText] = useState(true);
  const [showJournalText, setShowJournalText] = useState(true);
  const [showAccountText, setShowAccountText] = useState(true);

  const toggleText = () => setShowAdvancedText(!showAdvancedText);
  const toggleJournalText = () => setShowJournalText(!showJournalText);
  const toggleCRMText = () => setShowCRMText(!showCRMText);
  const toggleAccountText = () => setShowAccountText(!showAccountText);

  return (
    <>
      {/* <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW="80vw" // Maximum width: 80% of the viewport width
          maxH="80vh" // Maximum height: 80% of the viewport height
          width="80vw" // Set width to 80% of the viewport width
          height="80vh" // Set height to 80% of the viewport height
          className={isOpen ? "modal-open" : "modal-close"}
        >
          <ModalCloseButton
            position="fixed" // Make the close button fixed
            top="10px" // Adjust the top position as needed
            right="10px" // Adjust the right position as needed
            zIndex="1"
          />
          <ModalBody overflowY="auto"> */}
      <Flex
        justify="flex-start"
        align="center"
        py={0}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          // ref={orderRef}
        >
          <Heading fontSize="4xl" mb={4}>
            Transform your sales and supply chain with an open-source solution.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Manage inventory, orders, suppliers, and shipments—all in one simple
            system.
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          ></Stack>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
        border={showAdvancedText ? "2px solid #0000FF" : "none"} // Electric green border when toggle is on
        borderRadius="md" // Optional, for rounded corners
        boxShadow={showAdvancedText ? "0 0 10px rgba(0, 0, 255, 0.7)" : "none"} // Electric glow effect
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={salesdashboard}
            alt="Sales Dashboard"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Get an instant view of your sales and purchases in one place.
          </Heading>
          <Text fontSize="lg" mb={6}>
            {showAdvancedText
              ? "Use your voice to command your AI to create dashboards and generate deep insights"
              : "All your sales and purchase data in one place—custom dashboards to track performance easily."}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="view-toggle" mb="0">
                Supercharged with AI
              </FormLabel>
              <Switch
                id="view-toggle"
                isChecked={showAdvancedText}
                onChange={toggleText}
              />
            </FormControl>
          </Stack>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Manage product variations in a snap.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Store products and all their variations (color, size, shape) easily
            in your uniQ ERP tool.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={items}
            alt="Item Variants"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={trackserialnumbers}
            alt="Track Serial Numbers"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Track products with serial numbers for full lifecycle management.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Keep track of products from purchase to sale with serial numbers.
            Scan barcodes for fast access.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Easily manage batches of products, from manufacture to expiration.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Handle batches and expiration dates with ease. Automate batch naming
            and manage everything from procurement to sale.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={managelots}
            alt="Manage Lots"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={multichannel}
            alt="Multi-Channel Sales"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Sell across platforms like Shopify and Amazon with ease.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Integrate with Shopify, WooCommerce, or Amazon in just a few clicks.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Customize your documents and quotations to look great.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Make your documents and quotes look professional with customizable
            templates.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={printformats}
            alt="Print Formats"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
        border={showCRMText ? "2px solid #0000FF" : "none"} // Electric green border when toggle is on
        borderRadius="md" // Optional, for rounded corners
        boxShadow={showCRMText ? "0 0 10px rgba(0, 0, 255, 0.7)" : "none"} // Electric glow effect
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={marketingtools}
            alt="Marketing Tools"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Supercharge your marketing with our open-source CRM.
          </Heading>
          <Text fontSize="lg" mb={6}>
            {showCRMText
              ? "Our AI will power your digital campaigns with personalised communication and content"
              : "Boost your marketing with email campaigns, newsletters, and detailed performance analysis."}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="view-toggle" mb="0">
                Supercharged with AI
              </FormLabel>
              <Switch
                id="view-toggle"
                isChecked={showCRMText}
                onChange={toggleCRMText}
              />
            </FormControl>
          </Stack>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
        border={showJournalText ? "2px solid #0000FF" : "none"} // Electric green border when toggle is on
        borderRadius="md" // Optional, for rounded corners
        boxShadow={showJournalText ? "0 0 10px rgba(0, 0, 255, 0.7)" : "none"} // Electric glow effect
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Keep your accounts clean and organized for smooth transactions.
          </Heading>
          <Text fontSize="lg" mb={6}>
            {showJournalText
              ? "Let AI manage your cashflows - recording the transactions, providing hourly summaries and analyzing the financial reports - a dedicated finance professional for near-zero cost"
              : "Stay organized with clear records of income, expenses, and payments. Simplify cash flow management."}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="view-toggle" mb="0">
                Supercharged with AI
              </FormLabel>
              <Switch
                id="view-toggle"
                isChecked={showJournalText}
                onChange={toggleJournalText}
              />
            </FormControl>
          </Stack>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={generalledgersales}
            alt="Journal and Payments"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
        border={showAccountText ? "2px solid #0000FF" : "none"} // Electric green border when toggle is on
        borderRadius="md" // Optional, for rounded corners
        boxShadow={showAccountText ? "0 0 10px rgba(0, 0, 255, 0.7)" : "none"} // Electric glow effect
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={accountreceivable}
            alt="Accounts Receivable"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Track customer balances and easily manage payments.
          </Heading>
          <Text fontSize="lg" mb={6}>
            {showAccountText
              ? "Our AI will log your payments automatically by just sending in an email / message - it will also send customized reminders to your customers"
              : "Easily track and manage the money owed by customers for past invoices."}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="view-toggle" mb="0">
                Supercharged with AI
              </FormLabel>
              <Switch
                id="view-toggle"
                isChecked={showAccountText}
                onChange={toggleAccountText}
              />
            </FormControl>
          </Stack>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Provide clients with real-time access to their project and support
            information.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Give clients easy access to projects, support tickets, invoices, and
            more—all in one place.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={customeraccess}
            alt="Customer Access"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={email}
            alt="Unified, Tracked Emails"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Keep your email communication organized and in one place.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Keep all your email communications in one place for smooth,
            hassle-free interactions.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Easily upload and share project documents with your team.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Share and access project documents effortlessly with built-in upload
            and share features.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={documentstorage}
            alt="Documents Storage"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={stock}
            alt="Stock Replenishment"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Prevent stockouts by automating your inventory replenishment.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Automatically reorder stock before you run out—ensure inventory is
            always in stock.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Manage discounts and pricing rules with ease.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Easily manage discounts and promotional pricing for different
            customers and conditions.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={promotional}
            alt="Promotional Schemes"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={vendors}
            alt="Vendor Management"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Manage suppliers and purchase orders with ease.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Get alerts for RFQs & POs and handle supplier currencies with
            automatic exchange rate updates.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Give suppliers easy access to manage their orders.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Suppliers can submit quotes, track orders, and raise invoices—all in
            one place for easy management.
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={supplierportal}
            alt="Supplier Portal"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={accountspayable}
            alt="Accounts Payable"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Easily track what you owe and manage supplier payments.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Keep track of unpaid bills and payment due dates. Record payments
            quickly and import old invoices with ease.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Automated Invoicing
          </Heading>
          <Text fontSize="lg" mb={6}>
            Automate your monthly invoices with our Subscriptions feature for
            recurring orders!
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={autoinvoice}
            alt="Automated Invoicing"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={multicurrency}
            alt="Multi-Currency Sales"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Multi-Currency Sales
          </Heading>
          <Text fontSize="lg" mb={6}>
            Easily manage transactions in multiple currencies. Send invoices and
            add expenses in any currency, with AI-powered real-time conversion
            to your base currency. View financial transactions and generate
            reports in different currencies, with AI insights on financial
            impact.
          </Text>
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bgGradient="linear(to-r, gray.50, white)"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 1, md: 0 }}
        >
          <Heading fontSize="3xl" mb={4}>
            On-The-Fly Customizations
          </Heading>
          <Text fontSize="lg" mb={6}>
            Customize your accounting software to fit your needs—no coding
            required!
          </Text>
        </Box>
        <Box maxW="800px" mx="auto" order={{ base: 0, md: 1 }}>
          <Image
            src={customizations}
            alt="On-The-Fly Customizations"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Flex
        justify="flex-start"
        align="center"
        py={20}
        px={8}
        bg="gray.100"
        direction={{ base: "column", md: "row" }}
      >
        <Box maxW="800px" mx="auto" order={{ base: 1, md: 0 }}>
          <Image
            src={diy}
            alt="Self-Onboarding & Implementation"
            borderRadius="md"
            boxShadow="lg"
            maxW="600px"
            objectFit="cover"
          />
        </Box>
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          maxW="50%"
          width="100%"
          order={{ base: 0, md: 1 }}
        >
          <Heading fontSize="3xl" mb={4}>
            Get started with your uniQ ERP quickly, with no hassle.
          </Heading>
          <Text fontSize="lg" mb={6}>
            Set up and implement your ERP easily with step-by-step guides and
            video tutorials.
          </Text>
        </Box>
      </Flex>
      {/* </ModalBody>
        </ModalContent>
      </Modal> */}
    </>
  );
};

export default OrderManagement;
