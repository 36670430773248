import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  Stack,
  Image,
  Flex,
  SimpleGrid,
  Icon,
} from "@chakra-ui/react";
import {
  FaFileInvoiceDollar,
  FaShoppingCart,
  FaUsers,
  FaIndustry,
  FaProjectDiagram,
  FaChartPie,
  FaHeadset,
  FaBox,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"; // Import Framer Motion for animations
import { useInView } from "react-intersection-observer"; // For triggering animations when in view
import { Typewriter } from "react-simple-typewriter";
import uniqherocompressed from "../assets/uniq-hero-compressed.png";
import dashboard from "../assets/accounts-dashboard-slider.png";
import kanban from "../assets/kanban-job-dark.png";
import pos from "../assets/v13-pos.png";
import usermanagement from "../assets/13-form-view.png";
import projects from "../assets/gantt-v13.png";
import orders from "../assets/sales-order-13.png";
import language from "../assets/multilingual.png";
import google from "../assets/google.jpeg";
import mii from "../assets/make_in_india.png";
import amazon from "../assets/amazon.jpeg";
import nasscom from "../assets/nasscom.png";
import Slider from "react-slick";

// Motion components for animation using Framer Motion
const MotionBox = motion(Box);
const MotionImage = motion(Image);

const testimonials = [
  {
    id: 1,
    quote:
      "uniQ delivered a custom ERP that achieved nearly 100% automation, boosting productivity and allowing us to focus on creating beautiful fabrics. We couldn't be happier!",
    author: "Aarav Patel, TruStar Global Textiles, Gujarat",
  },
  {
    id: 2,
    quote:
      "uniQ's ERP solution optimizing operations through intelligent controls and automation. Our turnaround times have dramatically improved, allowing us to deliver quality work faster!",
    author: "Rahul Joshi, Classic Signage, Rajasthan",
  },
  {
    id: 3,
    quote:
      "We struggled with inventory and production management until we implemented uniQ ERP tool. It streamlined our processes, reduced material wastage by 30%, and helped us forecast demand more accurately. Our operations are now running much smoother.",
    author: "Rajesh Patel, Patel Textiles, Maharashtra",
  },
  {
    id: 4,
    quote:
      "We needed a solution that could manage our inventory and accounts. uniQ ERP tool has done just that, improving decision-making and communication. Our operations are now far more efficient, helping our farmers succeed.",
    author: "Gurmeet Singh, Farmers Cooperative, Haryana",
  },
];

const HeroSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [underlineStyle, setUnderlineStyle] = useState({ width: 0, left: 0 });
  const items = [
    { label: "Dashboards", image: dashboard },
    { label: "Multi-language Support", image: language },
    { label: "Kanban", image: kanban },
    { label: "Point of sale", image: pos },
    { label: "User Management", image: usermanagement },
    { label: "Projects", image: projects },
    { label: "Orders", image: orders },
  ];
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const itemRefs = useRef([]);
  const navigate = useNavigate();

  const handleLearnMore = (path) => {
    navigate(path);
  };

  useEffect(() => {
    // Measure the width and left position of the active item
    if (itemRefs.current[activeIndex]) {
      const { offsetWidth, offsetLeft } = itemRefs.current[activeIndex];
      setUnderlineStyle({ width: offsetWidth, left: offsetLeft });
    }
  }, [activeIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 3000); // 5 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [items.length]);

  return (
    <>
      {/* Hero Section */}
      <Flex
        justify="center"
        align="center"
        py={10}
        px={8}
        bgGradient="linear(to-r, #FFDEE9, #B5FFFC)" // Gen Z style gradient background
        direction={{ base: "column", md: "row" }}
      >
        <Box
          textAlign={{ base: "center", md: "left" }}
          py={10}
          px={6}
          width={{ base: "full", md: 600 }} // Make full width on mobile
        >
          <Text
            fontSize={{ base: "xl", md: "4xl" }} // Reduce size on mobile
            mb={0}
            fontWeight="bold"
            color="#4A4A4A" // Dark gray for better readability
            height={{ base: 100, md: 180 }}
          >
            <Typewriter
              words={[
                "Meet the ERP, powered by AI that keeps up with your business!",
                "Simplify your business processes with ease.",
                "Empower your team with AI-driven insights.",
                "Transform your workflow with our easy solution.",
                "Unlock the full potential of your business.",
              ]}
              loop={true} // Stops after one loop
              cursor
              cursorStyle="|"
              typeSpeed={100}
              deleteSpeed={20} // Disable deleting
              delaySpeed={1500}
            />
          </Text>
          <Text fontSize={{ base: "lg", md: "xl" }} mb={6} color="#7F7F7F">
            Experience unmatched flexibility and power
          </Text>
          <Stack
            direction="row"
            justify={{ base: "center", md: "flex-start" }}
            spacing={4}
          >
            <Button
              background="linear-gradient(135deg, #FF8A00, #E52E71)"
              color="white"
              borderRadius="full"
              _hover={{
                background: "linear-gradient(135deg, #E52E71, #FF8A00)",
              }}
              size={{ base: "md", md: "lg" }}
              px={8}
              fontWeight="bold"
              onClick={() =>
                window.open(
                  "https://calendly.com/ashish-avapadvisory",
                  "_blank"
                )
              }
            >
              Start Now
            </Button>
            {/* <Button
              background="linear-gradient(135deg, #8A2BE2, #4B0082)"
              color="white"
              borderRadius="full"
              _hover={{
                background: "linear-gradient(135deg, #4B0082, #8A2BE2)",
              }}
              size={{ base: "md", md: "lg" }}
              px={8}
              fontWeight="bold"
            >
              Explore Demo
            </Button> */}
          </Stack>
          <Stack align="flex-start" mt={2} spacing={4}>
            <Text fontSize={{ base: "md", md: "lg" }} mt={6} color="#7F7F7F">
              Incubated By:
            </Text>
            <Flex
              justify="flex_start"
              align="center"
              mt={0} // Margin to separate logos from the button
            >
              <Image
                src={amazon}
                alt="Amazon"
                boxSize="60px"
                objectFit="contain"
                mr={{ base: 2, md: 10 }}
              />
              <Image
                src={google}
                alt="Google"
                boxSize="60px"
                objectFit="contain"
                mr={{ base: 2, md: 10 }}
              />
              <Image
                src={mii}
                alt="Make in India"
                boxSize="60px"
                objectFit="contain"
                mr={{ base: 2, md: 10 }}
              />
              <Image
                src={nasscom}
                alt="Nasscom Startups"
                boxSize="60px"
                objectFit="contain"
              />
            </Flex>
          </Stack>
        </Box>
        <Box
          mt={{ base: 10, md: 0 }}
          ml={{ md: 10 }}
          display={{ base: "none", md: "block" }}
        >
          <MotionImage
            src={uniqherocompressed}
            alt="Dashboard Image"
            maxW="600px"
            objectFit="cover"
            borderRadius="2xl"
            boxShadow="2xl"
            whileHover={{
              scale: 1.05, // Slightly increase the size on hover
              rotate: 2, // Slightly rotate on hover
              boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)", // Add shadow effect
            }}
            transition={{
              duration: 0.3, // Animation duration
              ease: "easeInOut",
            }}
          />
        </Box>
      </Flex>
      <Box
        py={{ base: 0, md: 10 }}
        px={8}
        bgGradient="linear(to-r, #FFDEE9, #B5FFFC)"
      >
        <Text
          textAlign="left"
          fontSize={{ base: "xl", md: "4xl" }}
          mb={3}
          color="gray.700"
        >
          <Text as="span" fontWeight="bold">
            u
          </Text>
          nique.{" "}
          <Text as="span" fontWeight="bold">
            n
          </Text>
          ext-level.{" "}
          <Text as="span" fontWeight="bold">
            i
          </Text>
          ntuitive.{" "}
          <Text as="span" fontWeight="bold">
            Q
          </Text>
          uick.
        </Text>

        <Text textAlign="left" fontSize="lg" color="gray.600" mb={12}>
          Built by AI, for reliability and ease of use
        </Text>
        <Box borderBottom="1px" borderColor="gray.200" position="relative">
          <Flex
            justify="space-around"
            overflowX={["scroll", "auto"]} // Allow horizontal scroll for mobile
            wrap={["nowrap", "wrap"]} // No wrapping for mobile, wrapping for larger screens
            css={{
              "::-webkit-scrollbar": { display: "none" }, // Hide scrollbar for a cleaner look
            }}
          >
            {items.map((item, index) => (
              <Box
                key={index}
                ref={(el) => (itemRefs.current[index] = el)}
                onClick={() => setActiveIndex(index)}
                flex="0 0 auto" // Prevent items from shrinking and set them to auto width
                px={4}
                py={2}
                cursor="pointer"
                textAlign="center" // Aligns dots and text in the center
                position="relative" // Make the container relative for precise positioning
              >
                {/* Show item name only for the active item on mobile */}
                {activeIndex === index ? (
                  <Text
                    display={["block", "none"]} // Show only on mobile when active
                    fontWeight="bold"
                    fontSize="sm"
                    color="black"
                  >
                    {item.label}
                  </Text>
                ) : (
                  // Show dots for remaining items on mobile, aligned with the text
                  <Box
                    display={["block", "none"]} // Show only on mobile when not active
                    height="6px"
                    width="6px"
                    borderRadius="full"
                    bg="gray.400"
                    mx="auto"
                    position="absolute"
                    bottom="0" // Align the dot with the bottom of the box
                  />
                )}
                {/* Display item name for all items on larger screens */}
                <Text
                  display={["none", "block"]} // Hide on mobile, show on larger screens
                  fontWeight="bold"
                  fontSize={["sm", "md"]}
                  color={activeIndex === index ? "black" : "gray.600"}
                >
                  {item.label}
                </Text>
              </Box>
            ))}
          </Flex>
          {/* Underline indicator */}
          <Box
            position="absolute"
            bottom="0"
            height="2px"
            bg="black"
            transition="all 0.3s ease"
            width={`${underlineStyle.width}px`}
            left={`${underlineStyle.left}px`}
          />
        </Box>

        <Box display="flex" justifyContent="center" mt={4}>
          <MotionImage
            key={activeIndex} // Adding key to trigger re-render on index change
            src={items[activeIndex].image}
            alt={items[activeIndex].label}
            borderRadius="2xl"
            boxShadow="2xl"
            height={{ base: "200px", md: "600px" }} // You can adjust the size as needed
            width={{ base: "500pxpx", md: "1500px" }} // Adjust size as needed
            initial={{ opacity: 0 }} // Fade-out effect
            animate={{ opacity: 1 }} // Fade-in effect
            exit={{ opacity: 0 }} // Fade-out effect on exit
            transition={{ duration: 1 }} // Adjust duration for smoother effect
          />
          {/* <Image
            src={items[activeIndex].image}
            alt={items[activeIndex].label}
            initial={{ opacity: 0 }} // Fade-out effect
            animate={{ opacity: 1 }} // Fade-in effect
            exit={{ opacity: 0 }} // Fade-out effect on exit
            transition={{ duration: 0.5 }} // Adjust duration for smoother effect
          /> */}
        </Box>
      </Box>
      <Box
        py={{ base: 7, md: 10 }}
        px={8}
        bgGradient="linear(to-r, #FFDEE9, #B5FFFC)"
      >
        <Heading
          textAlign="center"
          fontSize={{ base: "2xl", md: "4xl" }}
          fontWeight="bold"
          mb={8}
          color="gray.700"
        >
          "Everything you need, nearly in one click!"
        </Heading>
        <Text textAlign="center" fontSize="lg" color="gray.600" mb={12}>
          Thousands of features, powered by AI to fit your every enterprise need
        </Text>
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          spacing={12}
          px={{ base: 4, md: 20 }}
        >
          <FeatureBox
            icon={FaFileInvoiceDollar}
            title="Financial Accounting"
            description="Let AI track your cash flow in real time—an all-in-one accounting module for every bookkeeping need!"
            onLearnMore={() => handleLearnMore("/erp/financial-accounting")}
          />
          <FeatureBox
            icon={FaShoppingCart}
            title="Order Management"
            description="Boost productivity and cut costs with AI seamlessly managing your sales and purchase cycles, from orders to delivery!"
            onLearnMore={() => handleLearnMore("/erp/order-management")}
          />
          <FeatureBox
            icon={FaUsers}
            title="HR and Payroll"
            description="Let AI oversee the entire employee journey—from onboarding and payroll to attendance, expenses, assets, and separation!"
            onLearnMore={() => handleLearnMore("/erp/hr-and-payroll")}
          />
          <FeatureBox
            icon={FaIndustry}
            title="Manufacturing"
            description="Effortlessly handle multi-level bills of materials, streamline production planning, job cards, and inventory management!"
            onLearnMore={() => handleLearnMore("/erp/manufacturing")}
          />
          <FeatureBox
            icon={FaChartPie}
            title="CRM"
            description="Attract and keep more customers by allowing AI to streamline your sales process—track leads, manage opportunities, and send quotes on the fly!"
            onLearnMore={() => handleLearnMore("/erp/crm")}
          />
          <FeatureBox
            icon={FaProjectDiagram}
            title="Project Management"
            description="Allow AI to complete internal and external projects on time and within budget—track tasks, timesheets, and issues effortlessly!"
            onLearnMore={() => handleLearnMore("/erp/projects")}
          />
          <FeatureBox
            icon={FaHeadset}
            title="Service Center"
            description="Enhance service quality with an AI-powered issue tracker and a seamless knowledge base at your fingertips!"
            onLearnMore={() => handleLearnMore("/erp/helpdesk")}
          />
          <FeatureBox
            icon={FaBox}
            title="Asset Management"
            description="Keep track of your assets— with AI to manage their details, movements, value adjustments, and depreciation with ease!"
            onLearnMore={() => handleLearnMore("/erp/asset-management")}
          />
        </SimpleGrid>
      </Box>
      <Box
        bgGradient="linear(to-r, #FFDEE9, #B5FFFC)"
        textAlign="center"
        py={12}
        px={6}
        mx="auto"
      >
        <Box
          bgGradient="transparent"
          textAlign="center"
          py={12}
          px={6}
          rounded="lg"
          mx="auto"
          maxW="xl"
        >
          <Text fontSize="2xl" color="purple.600" fontWeight="bold">
            Need help to decide?
          </Text>
          <Heading as="h2" size="xl" mt={4} fontWeight="bold">
            Find out which ERP modules are right for you and your business
          </Heading>
          <Text mt={2} color="gray.600">
            Book a call with an expert in less than 30 seconds
          </Text>
          <Button
            mt={8}
            colorScheme="teal"
            variant="solid"
            size="lg"
            onClick={() =>
              window.open("https://calendly.com/ashish-avapadvisory", "_blank")
            }
          >
            Help me find an expert now
          </Button>
        </Box>
      </Box>
      <Box
        p={4}
        bgGradient="linear(to-r, #FFDEE9, #B5FFFC)"
        textAlign="center"
        px={10}
        py={10}
      >
        <Slider {...sliderSettings}>
          {testimonials.map((testimonial) => (
            <Box
              key={testimonial.id}
              p={6}
              maxW="lg"
              shadow="md"
              rounded="md"
              bg="white"
              minH="150px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Text fontSize="xl" fontStyle="italic" color="gray.700">
                "{testimonial.quote}"
              </Text>
              <Text mt={4} fontWeight="bold" color="gray.600">
                - {testimonial.author}
              </Text>
            </Box>
          ))}
        </Slider>
      </Box>
    </>
  );
};

const FeatureBox = ({ icon, title, description, onLearnMore }) => {
  // Intersection Observer for triggering animations
  const { ref, inView } = useInView({
    triggerOnce: false, // Only animate once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });

  return (
    <MotionBox
      ref={ref}
      textAlign="center"
      p={4} // Reduced padding for mobile
      mb={2}
      bg="white"
      borderRadius="lg"
      boxShadow="lg"
      width={{ base: "100%", md: "auto" }} // Full width on mobile
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}} // Animate to visible state when in view
      transition={{ duration: 0.5 }}
      whileHover={{
        scale: 1.05,
        backgroundColor: "#f0f4ff",
        boxShadow: "xl",
      }}
    >
      <Icon
        as={icon}
        w={{ base: 8, md: 12 }}
        h={{ base: 8, md: 12 }}
        mb={2}
        color="teal.500"
      />
      <Heading
        fontSize={{ base: "lg", md: "2xl" }}
        mb={1}
        fontWeight="bold"
        color="gray.800"
      >
        {title}
      </Heading>
      <Text mb={2} fontSize={{ base: "md", md: "lg" }} color="gray.600">
        {description}
      </Text>
      {onLearnMore && (
        <Button
          colorScheme="teal"
          variant="solid"
          size={{ base: "sm", md: "md" }} // Smaller size for mobile
          onClick={onLearnMore}
        >
          Learn More
        </Button>
      )}
    </MotionBox>
  );
};

export default HeroSection;
