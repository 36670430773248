import React from "react";
import { Box, Text } from "@chakra-ui/react";

function ComingSoon() {
  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bgGradient="linear(to-r, #FFDEE9, #B5FFFC)"
      textAlign="center"
    >
      <Text fontSize="4xl" fontWeight="bold" color="gray.700">
        Coming Soon
      </Text>
      <Text fontSize="2xl" mt={4} color="gray.600">
        We are working hard to bring you the best experience.
      </Text>
    </Box>
  );
}

export default ComingSoon;
